<template>
  <div></div>
</template>

<script>
/**
 * Base logic of table components
 * @author Cristian Molina
 */
import sellerJson from "@/assets/json/sellers";
import consumersJson from "@/assets/json/consumers";
import countryJson from "@/assets/json/countrys";

export default {
  name: "util-table",
  props: ["headers", "items", "title", "subtitle", "priorityColor", "loading"],
  data: () => ({
    tableItems: [],
    availableSearchValues: [],
    foundedValues: [],
    sellers: [],
    consumers: [],
    countrys: [],
    search: null,
    select: null,
  }),
  /**
   * @description Mounted instance of component
   * @author Cristian Molina
   */
  mounted() {
    this.sellers = [...sellerJson];
    this.consumers = [...consumersJson];
    this.countrys = [...countryJson];
    this.fillTable();
  },
  watch: {
    /**
     * @description watch of tableItems modifications, load avaiblESearchValues for filter autocomplete.
     * @author Cristian Molina
     */
    tableItems() {
      let values = [];
      this.tableItems.forEach((item) => {
        let keys = Object.keys(item);
        keys.forEach((key) => {
          let founded = values.find((value) => value === item[key]);
          if (!founded && item[key]) {
            values.push(item[key].toString());
          }
        });
      });
      this.availableSearchValues = [...values];
      this.foundedValues = [...this.availableSearchValues.slice(0, 2)];
    },
    /**
     * @description watch of items
     * @param {any} val
     * @author Cristian Molina
     */
    items(val) {
      if (val?.length > 0) {
        this.fillTable();
      }
    },
    /**
     * @description watch of search value
     * @param {any} val
     * @author Cristian Molina
     */
    search(val) {
      val && val !== this.select && this.querySelections(val);
    },
    /**
     * @description watch of select value
     * @param {any} val
     * @author Cristian Molina
     */
    select(val) {
      this.filterTableWithSelectValue(val);
    },
  },
  methods: {
    /**
     * @description FillTable with with items values
     * @returns {any}
     * @author Cristian Molina
     */
    fillTable() {
      if (this.items?.length > 0 && this.headers?.length > 0) {
        let items = [...this.items];
        this.availableSearchValues = [];
        this.tableItems = [...items];
      }
    },
    /**
     * @description Reset filter value
     * @author Cristian Molina
     */
    resetFilter() {
      this.search = null;
      this.select = null;
    },
    /**
     * @description Get priority color
     * @param {any} v
     * @returns {any}
     * @author Cristian Molina
     */
    getPriorityColor(v) {
      const keys = Object.keys(this.priorityColor);
      let color = "";
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (
          this.priorityColor[key].from <= v &&
          this.priorityColor[key].to >= v
        ) {
          color = this.priorityColor[key].color;
        }
      }
      if (color === "") {
        color = "accent";
      }
      return color;
    },
    /**
     * @description Filter table with select value
     * @param {any} v
     * @returns {any}
     * @author Cristian Molina
     */
    filterTableWithSelectValue(v) {
      if (v?.length > 0) {
        this.tableItems = this.items.filter((e) => {
          const keys = Object.keys(e);
          let res = false;
          keys.forEach((key) => {
            if (v === e[key]?.toString()) {
              res = true;
            }
          });
          if (res) {
            return true;
          }
        });
      } else {
        this.tableItems = [...this.items];
      }
    },
    /**
     * @description Query of search in table
     * @param {any} v
     * @returns {any}
     * @author Cristian Molina
     */
    querySelections(v) {
      if (this.availableSearchValues?.length > 0) {
        this.foundedValues = this.availableSearchValues.filter((e) => {
          return (e || "").toLowerCase().indexOf((v || "").toLowerCase()) > -1;
        });
      }
    },
    /**
     * @description Edit Item
     * @param {any} item
     * @author Cristian Molina
     */
    editItem(item) {
      this.$emit("openForm", item, true);
    },
    /**
     * @description Create item
     * @author Cristian Molina
     */
    createItem() {
      let object = {};
      this.headers.forEach((header) => {
        object[header.value] = null;
      });
      this.$emit("openForm", object, false);
    },
    /**
     * @description Disable item
     * @param {any} item
     * @author Cristian Molina
     */
    disableItem(item) {
      this.$emit("disableObject", item);
    },
    /**
     * @description Show item
     * @param {any} item
     * @author Cristian Molina
     */
    showItem(item) {
      this.$emit("showObject", item);
    },
  },
};
</script>
