var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('div',{staticClass:"pa-4 pt-8"},[_c('v-card-actions',{staticClass:"px-12"},[_c('v-row',{attrs:{"align":"stretch"}},[_c('v-autocomplete',{attrs:{"search-input":_vm.search,"items":_vm.foundedValues,"label":"Filtrar elementos","outlined":"","dense":"","prepend-icon":"mdi-magnify"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","fab":"","small":"","elevation":"1"},on:{"click":function($event){return _vm.createItem()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1),(_vm.select)?_c('v-card-text',{staticClass:"mx-6 pa-4 pt-0"},[_vm._v(" Tabla filtrada por el valor "),_c('strong',[_vm._v(_vm._s(_vm.select))]),_c('v-btn',{staticClass:"ml-2",attrs:{"x-small":"","color":"accent","elevation":"1"},on:{"click":function($event){return _vm.resetFilter()}}},[_vm._v(" Restablecer filtro ")])],1):_vm._e(),_c('v-data-table',{staticClass:"ma-6 elevation-1 background",attrs:{"id":"datatbl","headers":_vm.headers,"items":_vm.tableItems,"loading":_vm.loading,"items-per-page":10},on:{"update:items":function($event){_vm.tableItems=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,i){return {key:("item." + (header.value)),fn:function(ref){
var item = ref.item;
return [(header.value.toLowerCase().search('priority') > -1)?_c('span',{key:i},[_c('v-chip',{attrs:{"color":_vm.getPriorityColor(item[header.value])}},[_vm._v(" "+_vm._s(item[header.value])+" ")])],1):(header.type === 'boolean')?_c('span',{key:i},[_c('v-icon',{attrs:{"color":item[header.value] ? 'success' : 'error'}},[_vm._v(" "+_vm._s(item[header.value] ? "mdi-check" : "mdi-close")+" ")])],1):(header.type === 'attributes')?_c('span',{key:i},[(item[header.value].length === 0)?_c('span',[_vm._v(" Vacio ")]):_c('span',[_c('v-menu',{attrs:{"transition":"slide-y-transition","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item[header.value].length)+" atributos ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-2",attrs:{"flat":""}},_vm._l((item[header.value]),function(val,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"id":("chip-" + i)}},[_c('span',[_vm._v(" "+_vm._s(_vm.getTextForAttributes(val.attributeCode))+" ")])])}),1)],1)],1)]):(header.type === 'product_conditions')?_c('span',{key:i},[(item[header.value].length === 0)?_c('span',[_vm._v(" Vacio ")]):_c('span',[_c('v-menu',{attrs:{"transition":"slide-y-transition","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","depressed":"","color":"primary"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item[header.value].length)+" condiciones de producto ")])]}}],null,true)},[_c('v-card',{staticClass:"pa-4",attrs:{"flat":""}},_vm._l((item[header.value]),function(val,i){return _c('v-chip',{key:i,staticClass:"ma-2",attrs:{"id":("chip-" + i)}},[_c('span',[_vm._v(" "+_vm._s(_vm.getTextForPCondition(val.conditionCode))+" ")])])}),1)],1)],1)]):(header.type === 'date')?_c('span',{key:i},[_vm._v(" "+_vm._s(_vm._f("date")(item[header.value]))+" ")]):(header.type === 'groupcode')?_c('span',{key:i},[_vm._v(" "+_vm._s(item[header.value])+" ")]):_c('span',{key:i},[_vm._v(" "+_vm._s(item[header.value])+" ")])]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!item.ruleSystem)?_c('v-icon',{staticClass:"mr-2",attrs:{"id":("edit-" + index),"disabled":_vm.loading},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),_c('v-icon',{staticClass:"mr-2",attrs:{"id":("disable-" + index),"disabled":_vm.loading},on:{"click":function($event){return _vm.disableItem(item)}}},[_vm._v(" "+_vm._s(item.status ? "mdi-toggle-switch" : "mdi-toggle-switch-off")+" ")]),_c('v-icon',{attrs:{"id":("show-" + index),"disabled":_vm.loading},on:{"click":function($event){return _vm.showItem(item)}}},[_vm._v(" mdi-eye ")])]}}],null,true)})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }